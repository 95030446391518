<template>
  <div>
    <dashboard-page-title :showAddBtn="false"
    >{{ $t('contactus.contacts') }}</dashboard-page-title>

    <main-table :fields="fields" :baseURL="SFZ_API_LINK" :list_url="contactsLink" :limitStatus="true"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SFZ_API_LINK } from '@/config/constant'
import { contactsLink } from '../services/contacts'

export default {
  name: 'contacts-index',
  mounted () {
    core.index()
  },
  data () {
    return {
      SFZ_API_LINK,
      contactsLink,
      fields: [
        { label: this.$t('main.subject'), key: 'subject' },
        { label: this.$t('main.email'), key: 'email' },
        {
          label: this.$t('main.actions'),
          key: 'actions',
          type: 'actions',
          actions: [
            {
              text: this.$t('main.view'),
              icon: 'ri-eye-fill',
              color: 'secondary',
              ifNavigate: true,
              routeName: 'contactus.view',
              routeParams: ['id']
            }
          ]
        }
      ]
    }
  }
}
</script>
<style>

</style>
